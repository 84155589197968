import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Form = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(null);

  const isValidPhone = (phoneno) =>
    /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/.test(
      phoneno
    );
  const submitHandler = async (data) => {
    if (!isValidPhone(phone)) {
      setPhoneError('This is not a valid phone no');
      return;
    } else {
      setPhoneError(null);
    }
    data.phone = phone;
    try {
      const url = `${process.env.API}/form`;
      console.log(`Sending form to : ${url}`);
      const response = await axios.post(url, JSON.stringify(data));
      console.log(`Response result form API : ${JSON.stringify(response)}`);
      toast.success('Your message has been received', {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      reset({});
      setPhone('');
    } catch (err) {
      toast.error('Sorry, Something went wrong', {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <form
        method='post'
        action='#'
        className='flex flex-col px-8 py-10 rounded-lg shadow-lg  border w-full md:w-3/4'
        onSubmit={handleSubmit(submitHandler)}
      >
        <div className='mb-3 flex flex-col'>
          <label htmlFor='name'>Name:</label>
          <input
            type='name'
            {...register('name', { required: 'This is a required field' })}
            id='name'
            className='border rounded border-gray-400 h-8 md:h-9 px-2 py-5'
            placeholder='Enter your name here'
          />
          {errors.name && (
            <p className='text-xs text-red-500 my-1 transition-all'>
              {errors.name.message}
            </p>
          )}
        </div>
        <div className=' flex flex-col'>
          <label htmlFor='email'>Email:</label>
          <input
            type='email'
            {...register('email', {
              required: 'This is a required field',
              pattern: {
                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'This is not a valid email address',
              },
            })}
            id='email'
            className='border rounded border-gray-400 h-8 md:h-9 px-2 py-5'
            placeholder='name@email.com'
          />
          {errors.email && (
            <p className='text-xs text-red-500 my-1 transition-all'>
              {errors.email.message}
            </p>
          )}
        </div>
        <div className='flex flex-col my-4 md:flex-row '>
          <p className='mr-0 mb-2 md:mb-0'>Requesting for a callback?</p>
          <div className='flex items-center ml-2 md:ml-6'>
            <input
              type='radio'
              name='callrequest'
              id='yes'
              className='mr-1'
              value='yes'
              checked
            />
            <label htmlFor='yes' className='mr-3'>
              Yes
            </label>
            <input
              type='radio'
              name='callrequest'
              id='no'
              className='mr-1'
              value='no'
            />
            <label htmlFor='no' className='mr-3'>
              No
            </label>
          </div>
        </div>
        <div className='mb-4 flex flex-col px-2 w-full'>
          <label htmlFor='phone'>Phone No:</label>
          <PhoneInput
            country={'us'}
            name='phone'
            value={phone}
            onChange={(phone) => setPhone(phone)}
            inputStyle={{
              maxWidth: '100%',
            }}
          />
          {phoneError && (
            <p className='text-xs text-red-500 my-1 transition-all'>
              {phoneError}
            </p>
          )}
        </div>
        <div className='mb-4 flex flex-col'>
          <label htmlFor='message'>Message:</label>
          <textarea
            {...register('mesg', {
              minLength: {
                value: 5,
                message: 'Message should be atleast 5 characters long',
              },
              required: 'This field is required',
            })}
            id='message'
            cols='30'
            rows='10'
            className='border rounded border-gray-400  px-2 py-2'
            placeholder='Enter your message here...'
          ></textarea>
          {errors.mesg && (
            <p className='text-xs text-red-500 my-1'>{errors.mesg.message}</p>
          )}
        </div>
        <button
          type='submit'
          className='w-full bg-gray-600 text-gray-100 p-2 rounded transition hover:shadow-lg'
        >
          Submit
        </button>
      </form>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />{' '}
    </>
  );
};

export default Form;
