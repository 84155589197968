import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Form from '../components/Form';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const Contact = () => {
  return (
    <Layout>
      <SEO siteTitle='Contact US' />
      <div className='h-48 overflow-hidden'>
        <StaticImage
          src='../images/contact-banner.png'
          placeholder='blurred'
          alt='Contact page Banner'
          className='bg-cover object-cover h-full'
        />
      </div>
      <div className='flex flex-col items-center px-10 py-20 md:p-24'>
        <h1 className='text-3xl font-semibold mb-8 md:mb-16'>Contact Us</h1>
        <div className='mb-20'>
          <p className='text-center font-normal'>
            Angel Valuation
            <p> 936 Huntington Drive, Suite B</p>
            <p> San Marino, CA 91108</p>
            ph:(626) 234-2470
          </p>
        </div>
        <div className='flex flex-col items-center  w-full'>
          <h4 className='text-2xl font-semibold mb-6'>Send a message</h4>
          <Form />
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
